import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serviceName',
})
export class ServiceNamePipe implements PipeTransform {
  transform(value: any) {
    if (value) {
      switch (value) {
        case 1:
          return 'Transporte';
        case 2:
          return 'Hospedaje';
        case 3:
          return 'Servicios';
        case 4:
          return 'Publicidad'
        default:
          return '';
      }
    }
    return '';
  }
}
