import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AuthInterceptorService } from './services/interceptors/auth-interceptor.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DeleteModalComponent } from './modals/delete-modal/delete-modal.component';
import { SharedModule } from './shared/shared.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { AlertModalComponent } from './modals/alert-modal/alert-modal.component';
import { InfoModalComponent } from './modals/info-modal/info-modal.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

const config: SocketIoConfig = {
  url: `${environment.apiSocket}`,
  options: { transports: ['websocket'], withCredentials: true },
};

@NgModule({
  declarations: [AppComponent, DeleteModalComponent, AlertModalComponent, InfoModalComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    IonicStorageModule.forRoot(),
    SharedModule,
    SocketIoModule.forRoot(config),
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    HttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [DeleteModalComponent],
})
export class AppModule {}
