<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title color="primary" style="text-align: center">{{ title }}</ion-title>
    <ion-buttons slot="end" style="position: absolute; right: 10px">
      <ion-button (click)="dismiss(false)" color="primary">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding ion-text-center">
  <p>{{ message }}</p>
  <ion-row>
    <ion-col size="6">
      <ion-button mode="ios" expand="block" (click)="dismiss(true)">
        {{ acceptButton }}
      </ion-button>
    </ion-col>
    <ion-col size="6" *ngIf="extraButton">
      <ion-button mode="ios" expand="block" (click)="dismiss(true, true)">
        {{ extraButton }}
      </ion-button>
    </ion-col>
    <ion-col [size]="extraButton ? 12 : 6">
      <ion-button mode="ios" expand="block" fill="outline" (click)="dismiss(false)"> Cancelar </ion-button>
    </ion-col>
  </ion-row>
</ion-content>
