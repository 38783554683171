import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit {
  @Input() title!: string;
  @Input() message!: string;
  @Input() acceptButton!: string;
  @Input() extraButton: string | null = null;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  dismiss(action: boolean, extra: boolean = false) {
    this.modalController.dismiss({ action: action, extra: extra });
  }
}
