import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() searchBar: boolean = false;
  @Input() public backUrl: string = '/home';
  @Output() public searchEvent = new EventEmitter<string>();
  public search: string = '';

  constructor(public storage: StorageService, public helper: HelperService) {}

  eventHandler(keyCode: any) {
    if (keyCode == 13) {
      document.getElementById('search')?.click();
    }
  }

  sendSearch() {
    const search = (<HTMLInputElement>document.getElementById('searchBar'))?.value;
    this.search = search;
    this.searchEvent.emit(search);
  }

  back() {
    this.helper.navigateRoot(this.backUrl);
  }

  ngOnInit() {}
}
