import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serviceStateUser',
})
export class ServiceStateUserPipe implements PipeTransform {
  transform(value: any) {
    if (value) {
      switch (value) {
        case 1:
          return 'Pendiente';
        case 2:
          return 'Presupuestado';
        case 3:
          return 'Rechazado';
        case 4:
          return 'Contratado';
        case 5:
          return 'Ignorado';
        case 6:
          return 'Completado';
        default:
          return '';
      }
    }
    return '';
  }
}
