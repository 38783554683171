<ion-content scroll-y="false">

  <ion-item lines="full">
    <ion-icon slot="start" name="warning-outline"></ion-icon>
    <ion-label text-wrap>
      <h1>
        {{title}}
      </h1>
      <p>
        {{message}}
      </p>
    </ion-label>
    <ion-icon slot="end" name="close-circle-outline" color="primary" (click)="dismiss(false)" style="cursor: pointer;"></ion-icon>
  </ion-item>
  
  <ion-row>
    <ion-col size="12">
      <ion-button mode="ios" (click)="dismiss(true)">
        {{acceptButton}}
      </ion-button>
      
      <ion-button mode="ios" fill="clear" (click)="dismiss(false)">
        Cancelar
      </ion-button>
    </ion-col>
  </ion-row>

</ion-content>


