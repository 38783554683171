import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serviceStandard',
})
export class ServiceStandardPipe implements PipeTransform {
  transform(value: any) {
    if (value) {
      switch (value) {
        case 1:
          return 'Economico';
        case 2:
          return 'Estandar';
        case 3:
          return 'Top';
        default:
          return '';
      }
    }
    return '';
  }
}
