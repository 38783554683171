import { DatePipe, formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'appTimeAgo',
  pure: false,
})
export class TimeAgoPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: string): string {
    let date = new Date(value);

    if (isNaN(date.getTime())) {
      return ''; // Maneja el caso de una cadena de fecha no válida
    }

    const now = new Date();
    const diff = Math.floor((now.getTime() - date.getTime()) / 60000); // Diferencia en minutos

    if (diff < 1) {
      return 'Ahora';
    } else if (diff === 1) {
      return '1 minuto';
    } else if (diff < 60) {
      return `${diff} minutos`;
    } else if (diff > 60 && diff < 1440) {
      return formatDate(date, 'hh:mm', this.locale);
    } else if (diff > 1440 && diff < 2880) {
      return 'Ayer';
    } else {
      return formatDate(date, 'dd/MM/yy', this.locale);
    }
  }
}
