import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';
import { HelperService } from '../helper/helper.service';

/**
 * @class AuthInterceptorService
 * @description Interception the all petition
 */

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {

  public token:string|undefined|null;

  constructor(
    private storage: StorageService,
    private helper: HelperService
  ) {}

  /**
   * @description Method intercept
   * @param request the request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token = this.storage.token;
    if (this.token !== undefined && this.token !== null && this.token !== '') {
      const headers = request.headers
        .set('Authorization', 'Bearer ' + this.token)
        .set('Accept', 'application/json');
      request = request.clone({ headers });
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => event),
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
    } else {
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => event),
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
    }
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401 || error.status === 403) {
      this.helper.navigateRoot('login');
      this.storage.remove('token');
    }
    return throwError(() => new Error(error.message))
  }
}
  