import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serviceZone',
})
export class ServiceZonePipe implements PipeTransform {
  transform(value: any) {
    if (value) {
      switch (value) {
        case 1:
          return 'Centrica';
        case 2:
          return 'A las afueras';
        default:
          return '';
      }
    }
    return '';
  }
}
