import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent implements OnInit {
  @Input() public title: string =
    '¿Estás seguro que queres eliminar este contenido?';
  @Input() public message: string = 'No podrás recuperarlo luego.';
  @Input() public acceptButton: string = 'Eliminar';

  constructor(public modalController: ModalController) {}

  ngOnInit() {}

  dismiss(confirm: boolean) {
    this.modalController.dismiss({
      delete: confirm,
    });
  }
}
