import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './components/header/header.component';
import { RoleNamePipe } from './pipes/role-name.pipe';
import { NoEInputNumberDirective } from './directives/no-e-input-number.directive';
import { TransportPreferencePipe } from './pipes/transport-preference.pipe';
import { ServiceZonePipe } from './pipes/service-zone.pipe';
import { ServiceStandardPipe } from './pipes/service-standard.pipe';
import { ServiceNamePipe } from './pipes/service-name.pipe';
import { ServiceStateUserPipe } from './pipes/service-state-user.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { MovementTypePipe } from './pipes/movement-type.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    RoleNamePipe,
    MovementTypePipe,
    NoEInputNumberDirective,
    TransportPreferencePipe,
    ServiceZonePipe,
    ServiceStandardPipe,
    ServiceNamePipe,
    ServiceStateUserPipe,
    ServiceStateUserPipe,
    TimeAgoPipe,
  ],
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, RouterModule],
  exports: [
    HeaderComponent,
    RoleNamePipe,
    MovementTypePipe,
    NoEInputNumberDirective,
    TransportPreferencePipe,
    ServiceZonePipe,
    ServiceStandardPipe,
    ServiceNamePipe,
    ServiceStateUserPipe,
    TimeAgoPipe,
  ],
})
export class SharedModule {}
