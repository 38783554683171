import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoEInputNumber]',
})
export class NoEInputNumberDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  }
}
