import { Injectable } from '@angular/core';
import {
  ToastController,
  LoadingController,
  AlertController,
  NavController,
} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(
    public toastController: ToastController,
    public loadingController: LoadingController,
    public alertController: AlertController,
    private navCtrl: NavController
  ) {}

  async showToast(message: any, duration = 3000, color = 'dark') {
    var toast = await this.toastController.create({
      message: message,
      duration: duration,
      color: color,
    });
    toast.present();
  }

  createLoading(message: any) {
    return this.loadingController.create({
      message: message,
      mode: 'ios',
      spinner: 'bubbles',
    });
  }

  async showAlert(message: any, header = '') {
    const alert = await this.alertController.create({
      header: header,
      mode: 'ios',
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  navigateRoot(route: any, queryParams = {}) {
    this.navCtrl.navigateRoot([route], { queryParams, replaceUrl: true });
  }

  navigateForward(route: any, queryParams = {}) {
    this.navCtrl.navigateForward([route], { queryParams, replaceUrl: true });
  }

  navigateBack() {
    this.navCtrl.pop();
  }

  async loader() {
    const loader = await this.loadingController.create({
      message: '',
      spinner: 'crescent',
      translucent: false,
      mode: 'md',
      cssClass: 'loading-hide',
    });

    await loader.present();
    return loader;
  }
}
