import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { HelperService } from '../services/helper/helper.service';
import { StorageService } from '../services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public storage: StorageService,
    private helper: HelperService
  ){}
  canActivate(route: ActivatedRouteSnapshot) {
    var path = route.routeConfig?.path;
    return new Promise<boolean>(resolve=>{
      this.storage.get('token')?.then(data=>{
        if(path === 'login'){
          if(data){
            console.log('ya esta logueado');
            this.helper.navigateRoot('home');
            resolve(false);
          }else{
            resolve(true);
          }
        }else{
          if(data){
            resolve(true);
          }else{
            console.log('no esta logueado');
            this.helper.navigateRoot('login');
            resolve(false);
          }
        }

      })
    })
  }
  
}
