import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transportPreference',
})
export class TransportPreferencePipe implements PipeTransform {
  transform(value: any) {
    if (value) {
      switch (value) {
        case 1:
          return 'Avion';
        case 2:
          return 'Tren';
        case 3:
          return 'Bus';
        case 4:
          return 'Hotel';
        case 5:
          return 'Departamento temporario';
        default:
          return '';
      }
    }
    return '';
  }
}
