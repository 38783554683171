import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'movementType',
})
export class MovementTypePipe implements PipeTransform {
  movements: { id: string; name: string }[] = [
    {
      id: "1",
      name: 'Ingreso',
    },
    {
      id: "2",
      name: 'Egreso',
    }
  ];

  transform(movement_id: string): unknown {

    console.log(movement_id);
    const movement = this.movements.find((movement) => movement.id === movement_id);
    if (movement) {
      return movement.name;
    }
    return '';
  }
}
