import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roleName',
})
export class RoleNamePipe implements PipeTransform {
  roles: { id: number; name: string }[] = [
    {
      id: 1,
      name: 'Modelx',
    },
    {
      id: 2,
      name: 'Guía traductor',
    },
    {
      id: 3,
      name: 'Administrador',
    },
    {
      id: 4,
      name: 'Presentador',
    },
  ];

  transform(role_id: number): unknown {
    const role = this.roles.find((role) => role.id === role_id);
    if (role) {
      return role.name;
    }
    return '';
  }
}
