<ion-header class="ion-padding ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button mode="ios" color="dark" (click)="back()">
        <ion-icon slot="start" name="chevron-back"></ion-icon>
        Atrás
      </ion-button>
    </ion-buttons>

    <ion-toolbar class="searchbar" *ngIf="searchBar">
      <ion-searchbar
        placeholder="Buscador"
        mode="md"
        (keypress)="eventHandler($event.keyCode)"
        id="searchBar"
      ></ion-searchbar>
      <ion-button mode="ios" slot="end" id="search" (click)="sendSearch()"> Buscar </ion-button>
    </ion-toolbar>

    <ion-button slot="end" (click)="storage.logout()" fill="outline" mode="ios">
      <ion-icon slot="start" name="logout"></ion-icon>
      Cerrar sesión
    </ion-button>
  </ion-toolbar>
</ion-header>
