import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public _storage: Storage | null = null;
  public token:string|undefined|null;

  constructor(
    private storage: Storage,
    public helper: HelperService
  ) { 
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
    this._storage.get('token')?.then(token=>{
      this.token = token;
    })
  }

  set(key: string, value: any) {
    this._storage?.set(key, value);
    if(key === 'token'){
      this.token = value;
    }
  }

  get(key:string){
    return this._storage?.get(key);
  }

  remove(key:string){
    this._storage?.remove(key);
  }

  logout(){
    this.remove('token');
    this.helper.navigateRoot('login');
  }
}
